import * as React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import {useEffect, useState} from "react";

export default function Breadcrumbs({firstChild, secondChild, firstChildUrl, secondChildUrl, thirdChild, thirdChildUrl}) {
    const { t } = useTranslation();
    let [thisPageUrl, setThisPageUrl] = useState('');
    useEffect(() => {
        setThisPageUrl(window.location.href);
    }, [])


    return (
        <section className="breadcrumbs">
            {firstChild &&
                <ol itemScope itemType="https://schema.org/BreadcrumbList" className="custom-scrollbar__mobile d-flex justify-content-start align-items-center ps-0 mb-0">
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem"
                        className="position-relative">
                        <Link to={'/'} itemProp="item">
                            <span itemProp="name">{t('homePageWord')}</span>
                        </Link>
                        <meta itemProp="position" content="1"/>
                    </li>
                    <li itemProp="itemListElement" itemScope
                        itemType="https://schema.org/ListItem"
                        className="position-relative">
                        {firstChildUrl ?
                            <Link to={firstChildUrl} itemProp="item">
                                <span itemProp="name">{t(firstChild)}</span>
                            </Link>
                            :
                            <a content={thisPageUrl} itemProp="item">
                                <span itemProp="name">{t(firstChild)}</span>
                            </a>
                        }
                        <meta itemProp="position" content="2"/>
                    </li>
                    {secondChild &&
                        <li itemProp="itemListElement" itemScope
                            itemType="https://schema.org/ListItem"
                            className="position-relative">
                            {secondChildUrl ?
                                <Link to={secondChildUrl} itemProp="item">
                                    <span itemProp="name">{t(secondChild)}</span>
                                </Link>
                                :
                                <a content={thisPageUrl} itemProp="item">
                                    <span itemProp="name">{t(secondChild)}</span>
                                </a>
                            }
                            <meta itemProp="position" content="3"/>
                        </li>
                    }
                    {thirdChild &&
                        <li itemProp="itemListElement" itemScope
                            itemType="https://schema.org/ListItem"
                            className="position-relative">
                            {thirdChildUrl ?
                                <Link to={thirdChildUrl} itemProp="item">
                                    <span itemProp="name">{t(thirdChild)}</span>
                                </Link>
                                :
                                <a content={thisPageUrl} itemProp="item">
                                    <span itemProp="name">{t(thirdChild)}</span>
                                </a>
                            }
                            <meta itemProp="position" content="4"/>
                        </li>
                    }
                </ol>
            }
        </section>
    )
}