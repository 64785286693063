import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Container} from "react-bootstrap";

export default function PageHeaderBlock({h1}) {
    const { t } = useTranslation();

    return (
        <section className="grad">
            <Container fluid className="header-block header-tittle px-flex d-flex justify-content-center align-items-center">
                {h1 &&
                    <h1 className="text-center my-3 my-md-4 my-xl-5 pt-5 pb-2 pb-xl-3">{t(h1)}</h1>
                }
            </Container>
        </section>
    )
}